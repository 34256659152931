import React from 'react'
import Layout from "../../components/layout/Layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { css } from '@emotion/react'

export default function Page() {
  return (

<Layout>
  <Seo title="Dr. Trent W. Listello Colorado Springs - pikespeakoralsurgery" description="Dr. W. Listello’s goal is  to create a highly coordinated oral and maxillofacial surgery facility where the patient’s outcome and satisfaction are the top priority." />
  <section className="container" style={{margin: '128px auto'}}>

    <StaticImage src="../../images/dr-trent.jpg" width={816} height={459} alt="doctor trent listello" css={css`
        border-radius: 16px;
        opacity: 0.9999; //safari overflow bug fix
        max-width: 816px;
        display: block;
        margin: 0 auto 64px;
        filter: drop-shadow(0px 16px 30px rgba(140, 124, 101, 0.3));
    
    `} />

<h2>About Dr. Trent W. Listello</h2>

<p>Pikes Peak Oral Surgery has had the pleasure of serving the Colorado Springs community for the past seven years. We are grateful to our patients and referring dentists for your incredible support. As always, we strive to build a foundation of trust by treating our patients with compassion and a focus on providing individualized care. In an effort to ensure that we can continue this promise and commitment, we are proud to announce that Dr. Trent W. Listello will be joining our practice. He is excited to join our practice following his transition out of the US Air Force where he served for 20 years. He is a board certified Oral and Maxillofacial Surgeon and his experience and service will be a great asset to our patients.</p>

<h4>ORIGINS</h4>

<p>Dr. Trent W. Listello is originally from Washington State where he earned his bachelor's degree in Biology from Eastern Washington University. He attended dental school at Loma Linda University, graduating with honors in 2000. While at Loma Linda, he won a scholarship from the US Air Force. His first assignment was here in Colorado Springs at the US Air Force Academy as part of the first class of Advanced Education in General Dentistry. It was then that he decided that he loved Colorado and wanted to return someday.</p>

<p>Dr. Trent W. Listello completed his Oral & Maxillofacial training at David Grant Medical Center at Travis AFB, California in 2009. He was then stationed in England for four years, serving one year as the only Oral Surgeon for the US military in England! His final assignment was at USAFA as he always wanted, returning in 2013 with his three boys. He is excited to serve in the community that he has loved for 20 years.</p>

<h4>HOBBIES</h4>

<p>When Dr. Trent W. Listello is not at work, he loves the Rocky Mountains and its many outdoor activities. His interests include hiking 14ers, golfing, and mountain biking, while with friends and family.</p>

<p>Please welcome him to our practice! Dr. Trent W. Listello looks forward to getting acquainted and helping Pikes Peak Oral Surgery provide excellent patient care to our community.</p>

</section>

</Layout>

  )
}
